import {
  Flex,
  Link,
  useDisclosure,
  Grid,
  GridItem,
  Spinner,
  Stack,
  Input,
  useColorModeValue,
  theme,
} from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa6";
import React, { useContext, useEffect, useState } from "react";
import BillingCard from "../BillingCard/BillingCard";
import { Heading } from "@chakra-ui/react";
import { Box, Text, Button } from "@chakra-ui/react";
import ConfirmationModal from "../../Modals/Confirmation";
import { fetchController } from "../../../utils/FetchController/fetchController";
import dateFormat from "../../../utils/DateFormat/dateFormat";
import CouponSuccessModal from "../../Modals/CouponSuccessModal";
import Loader from "../../../utils/Loader/Loader";
import AddonsList from "./AddonsList";
import { formatDateWithAmPm } from "../../../utils/DateFormat/dateFormatWithTimeAmPm";
import { toast } from "react-toastify";
import BillingPortalButton from "./BillingPortalButton";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../../utils/utils";

const Billing = ({ tab, activeTab }) => {
  const baseUrl = getHostDomain();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCouponSuccessModalOpen,
    onOpen: onCouponSuccessModalOpen,
    onClose: onCouponSuccessModalClose,
  } = useDisclosure();
  const [type, setType] = useState("");
  const [billingPlans, setBillingPlans] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [cancelSubsIsLoading, setCancelSubsIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState(null);
  const [couponCodeSuccessMsg, setCouponCodeSuccessMsg] = useState("");
  const [couponCodeIsLoading, setCouponCodeIsLoading] = useState(false);
  const [coupanBillingPlans, setCoupanBillingPlans] = useState([]);
  const [isCouponPlan, setIsCouponPlan] = useState(false);

  const handleDelete = () => {
    setType("delete");
    onOpen();
  };
  const handleClose = () => {
    setType("close");
    onOpen();
  };
  const deleteBilling = async () => {
    console.log("delete");
  };
  const CloseBilling = async () => {
    console.log("close");
  };

  const getBillingPlan = async () => {
    setIsLoading(true);
    try {
      const billin_plan = await fetchController(
        baseUrl + `/api/v1/billing/get_active_billing_plans`,
        "GET"
      );

      const userDetailsRes = await fetchController(
        baseUrl + `/api/v1/user`,
        "GET"
      );

      const selectPlan = planUpgradeOrDowngrade(
        userDetailsRes.data.active_billing_plan_id,
        billin_plan.data.items
      );

      setUserDetails(userDetailsRes.data);
      let queryCountOfActivePlan = null;
      const isSpecialPlan =
        billin_plan.data.items.filter(
          (val, i) => val.id == userDetailsRes.data.active_billing_plan_id
        ).length > 0
          ? false
          : true;
      if (!isSpecialPlan) {
        queryCountOfActivePlan = billin_plan.data.items.filter(
          (val, i) => val.id == userDetailsRes.data.active_billing_plan_id
        )[0]["queries_count"];
      }

      setIsCouponPlan(isSpecialPlan);
      let formattedData = billin_plan.data.items.map((data, index) => {
        return {
          id: data.id,
          plan: data.plan_lookup_key,
          isActive: isSpecialPlan
            ? false
            : queryCountOfActivePlan
              ? userDetailsRes.data.active_billing_plan_id == data.id
              : data.plan_lookup_key === "insightoai_free"
                ? true
                : false,
          label: selectPlan[data.plan_lookup_key],
          isDisable:
            isSpecialPlan && data.plan_lookup_key === "insightoai_free"
              ? true
              : userDetailsRes.data.is_trial
                ? queryCountOfActivePlan &&
                  queryCountOfActivePlan > data.queries_count
                  ? true
                  : false
                : false,
          features: [
            {
              [data.plan_lookup_key === "insightoai_platinum" ||
                data.plan_lookup_key === "insightoai_diamond"
                ? "Unlimited bots"
                : "Bots count"]:
                data.plan_lookup_key === "insightoai_platinum" ||
                  data.plan_lookup_key === "insightoai_diamond"
                  ? " "
                  : data.bots_count,
              "Queries count": data.queries_count,
              "Words count": data.words_count,
              "Voice seconds": data.voice_seconds,
              "Remove Branding": data.remove_branding,
              "Add Form": data?.credits?.form ? true : false,
              // "Social Channels (twilio, whatsApp (coming soon))":
              //   data.plan_lookup_key === "insightoai_gold" ||
              //   data.plan_lookup_key === "insightoai_platinum" ||
              //   data.plan_lookup_key === "insightoai_diamond"
              //     ? true
              //     : false,
              [`Social Channels ${data?.credits?.channels
                  ? "(" +
                  ConvertArrayValueToCapitalize(
                    Object.keys(data?.credits?.channels)
                  ).join(",") +
                  ")"
                  : ""
                }`]: data?.credits?.channels ? true : false,

              "Your Voice": data?.credits?.custom_voice ? true : false,
              "Auto Intent": data.auto_intents,
              "Advanced Intent": data.advanced_intents,
            },
          ],
          pricing: data.description,

          popular: data.plan_lookup_key == "insightoai_platinum",

          name: data.name,
          is_trial: userDetailsRes.data.is_trial,
          description: data.description,
          erlyBirdsOffer:
            data.plan_lookup_key === "insightoai_gold"
              ? process.env.REACT_APP_TRIAL_DURATION
                ? `Early Adopters - ${process.env.REACT_APP_TRIAL_DURATION}  free`
                : ""
              : "",
        };
      });

      if (isSpecialPlan) {
        const specialPlanRes = await fetchController(
          baseUrl +
          `/api/v1/billing/billing_plan/${userDetailsRes.data.active_billing_plan_id}`,
          "GET"
        );

        const speciaPlanFormattedData = [specialPlanRes.data].map(
          (data, index) => {
            return {
              id: data.id,
              plan: data.description,
              isActive: true,
              label: "Activated",
              features: [
                {
                  [data.bots_count >= 999999 ? "Unlimited bots" : "Bots count"]:
                    data.bots_count >= 999999 ? "" : data.bots_count,
                  "Queries count": data.queries_count,
                  "Words count": data.words_count,
                  "Voice seconds": data.voice_seconds,
                  "Remove Branding": data.remove_branding,
                  "Add Form": data?.credits?.form ? true : false,
                  [`Social Channels ${data?.credits?.channels
                      ? "(" +
                      Object.keys(data?.credits?.channels).join(",") +
                      ")"
                      : ""
                    }`]: data?.credits?.channels ? true : false,
                  "Your Voice": data?.credits?.custom_voice ? true : false,
                  "Auto Intent": data.auto_intents,
                },
              ],
              popular: data.plan_lookup_key == "insightoai_platinum",
              name: data.name,
              description: data.description,
            };
          }
        );
        setCoupanBillingPlans(speciaPlanFormattedData);
      }

      const subscribedOrNotArr = billin_plan.data.items.filter(
        (val, i) =>
          val.id == userDetailsRes.data.active_billing_plan_id &&
          val.plan_lookup_key !== "insightoai_free"
      ).length;
      setIsSubscribed(subscribedOrNotArr ? true : false);
      setBillingPlans(formattedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const planUpgradeOrDowngrade = (currentPlanId, billingPlans = []) => {
    const currentPlanIndex = billingPlans
      .map((billingPlan) => billingPlan.id)
      .indexOf(currentPlanId);
    const decideUpgradeOrDowngrade = (acc, prev, index) => {
      acc[prev.plan_lookup_key] =
        currentPlanIndex >= 0
          ? index > currentPlanIndex
            ? "upgrade"
            : "downgrade"
          : "switch";
      return acc;
    };
    const allBillingPlansLookupKeys = billingPlans.reduce(
      decideUpgradeOrDowngrade,
      {}
    );
    return allBillingPlansLookupKeys;
  };

  useEffect(() => {
    if (activeTab === "billing") {
      getBillingPlan();
    }
  }, [activeTab]);

  const HanleClickOnAddPaymentMethod = async () => {
    try {
      setIsLoading(true);
      const addPaymentMethodResponse = await fetchController(
        baseUrl + `/api/v1/billing/add-payment-method`,
        "GET"
      );
      if (addPaymentMethodResponse.status === "redirect") {
        window.open(addPaymentMethodResponse.checkout_url, "_blank");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleClickOnCancelSubsription = async (planName) => {
    try {
      setCancelSubsIsLoading(true);
      const body = {
        lookup_key: planName.replace(/\./g, ""),
      };
      const getData = await fetchController(
        baseUrl + `/api/v1/billing/create-checkout-session`,
        "POST",
        body
      );
      if (getData.checkout_url) {
        window.open(getData.checkout_url, "_blank");
      } else {
        console.error("Checkout URL not found in the response");
      }
    } catch (error) {
      console.error("Error while cancel Subscription:", error);
    } finally {
      setCancelSubsIsLoading(false);
    }
  };
  const handleOnApplyCouponCode = async () => {
    setCouponCodeIsLoading(true);
    setIsLoading(true);
    try {
      const endpoint = "/api/v1/billing/apply_coupon";
      const urlHit = baseUrl + endpoint;

      const body = {
        coupon_code: couponCode.toString(),
      };
      const getData = await fetchController(urlHit, "POST", body);
      if (getData.status === "success") {
        setCouponCodeSuccessMsg(getData.message);
        onCouponSuccessModalOpen();
        setCouponCodeError(null);
        setTimeout(() => {
          onCouponSuccessModalClose();
          window.location.reload();
        }, 5000);
      } else {
        setCouponCodeError(getData.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setCouponCodeIsLoading(false);
    }
  };

  const ConvertArrayValueToCapitalize = (arr = []) => {
    return arr.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg, agencyData, myDetails } =
    agency;

  const isSalesBtnisvisible = agency.loading
    ? false
    : myDetails?.agency_id
      ? false
      : true;
  return (
    <>
      <Box border="1px" h="81vh" overflowY="scroll" p="5" borderRadius="md">
        <Heading fontSize="xl" color={textColor}>
          Plans
        </Heading>
        {isLoading ? (
          <Loader text={couponCodeIsLoading ? "Processing" : ""} />
        ) : (
          <Flex
            direction={"column"}
            // justifyContent="center"
            // alignItems="center"
            width="100%"
          >
            <Flex
              gap={3}
              // justifyContent="center"
              // alignItems="center"
              width="100%"
              flexWrap={{ base: "wrap", xl: "nowrap" }}
            >
              {billingPlans.map((item, index) => {
                return (
                  <BillingCard
                    key={index}
                    features={item.features}
                    popular={item.popular}
                    active={item.isActive}
                    planName={item.name}
                    lookup_key={item.plan}
                    pricing={item.pricing}
                    item={item}
                    label={item.label}
                    erlyBirdsOffer={item.erlyBirdsOffer}
                    isDisable={item.isDisable}
                    isTrial={item.is_trial}
                    bg={cardBg}
                    color={textColor}
                    buttonColorScheme={buttonColorScheme}
                  />
                );
              })}
            </Flex>

            <Text fontSize="18px" p={3}>
              {userDetails.is_trial && <>You're currently using our trial.</>}
            </Text>

            <Grid
              // width="100%"
              // height={"400px"}
              templateColumns="repeat(12, 1fr)"
              gap={2}
            >
              <GridItem colSpan={{ base: "12", sm: "6", lg: "4" }}>
                <Grid templateColumns="repeat(12, 1fr)" gap={2}>
                  {myDetails?.agency_id ? null : (
                    <GridItem
                      colSpan={12}
                      bg={cardBg}
                      p={4}
                      borderRadius="8px"
                      boxShadow="md"
                      height={"200px"}
                      overflow={"auto"}
                    >
                      <Flex
                        marginTop={isCouponPlan ? "-25px" : "0"}
                        direction={"column"}
                        gap={2}
                      >
                        {coupanBillingPlans.map((item, index) => {
                          return (
                            <BillingCard
                              key={index}
                              features={item.features}
                              popular={false}
                              active={item.isActive}
                              planName={item.plan}
                              pricing={item.pricing}
                              item={item}
                              label={item.label}
                              erlyBirdsOffer={item.erlyBirdsOffer}
                              isDisable={item.isDisable}
                              isCoupan={true}
                            />
                          );
                        })}

                        <Stack spacing={0} direction={"column"}>
                          <Stack spacing={3} direction={"row"}>
                            <Input
                              placeholder="Coupon code"
                              value={couponCode}
                              onChange={(e) => {
                                setCouponCode(e.target.value);
                                setCouponCodeError("");
                              }}
                              size="md"
                            />
                            <Button
                              colorScheme={buttonColorScheme}
                              isLoading={couponCodeIsLoading}
                              // loadingText="Processing"
                              isDisabled={couponCode ? false : true}
                              onClick={handleOnApplyCouponCode}
                            >
                              Apply
                            </Button>
                          </Stack>
                          {couponCodeError && (
                            <>
                              <Text color={"red"} p={1}>
                                {couponCodeError}
                              </Text>
                            </>
                          )}
                        </Stack>
                      </Flex>
                    </GridItem>
                  )}

                  {isSalesBtnisvisible && (
                    <>
                      <GridItem
                        // colSpan={4}
                        // rowSpan={1}
                        bg={cardBg}
                        p={4}
                        borderRadius="8px"
                        boxShadow="md"
                        colSpan={12}
                      >
                        <Flex direction={"column"} gap={4}>
                          <Box>
                            <Text fontSize="12px" fontWeight="bold">
                              Need higher limits?{" "}
                            </Text>
                            <Flex
                              direction={"row"}
                              gap={2}
                              alignItems={"center"}
                            >
                              <Text fontSize="12px" fontWeight="bold" mt={2}>
                                <FaCheck size={15} color="#3182ce" />
                              </Text>
                              <Text fontSize="12px" fontWeight="bold">
                                Diamond Plan +
                              </Text>
                            </Flex>
                            <Flex
                              direction={"row"}
                              gap={2}
                              alignItems={"center"}
                            >
                              <Text fontSize="12px" fontWeight="bold" mt={2}>
                                <FaCheck size={15} color="#3182ce" />
                              </Text>
                              <Text fontSize="12px" fontWeight="bold">
                                Higher limits
                              </Text>
                            </Flex>

                            <Flex
                              direction={"row"}
                              gap={2}
                              alignItems={"center"}
                            >
                              <Text fontSize="12px" fontWeight="bold" mt={2}>
                                <FaCheck size={15} color="#3182ce" />
                              </Text>
                              <Text fontSize="12px" fontWeight="bold">
                                Enterprise Integrations such as HRMS, ITSM, CRM
                                amongst many others
                              </Text>
                            </Flex>
                          </Box>
                          <Button
                            colorScheme={buttonColorScheme}
                            width="160px"
                            display="block"
                          >
                            <Link
                              colorScheme={buttonColorScheme}
                              target="_blank"
                              href="mailto:support@insighto.ai"
                              style={{ textDecoration: "none" }}
                            >
                              Contact Sales
                            </Link>
                          </Button>
                        </Flex>
                      </GridItem>
                    </>
                  )}
                </Grid>
              </GridItem>

              <GridItem colSpan={{ base: "12", sm: "6", lg: "4" }}>
                <Grid templateColumns="repeat(12, 1fr)" gap={2}>
                  <GridItem colSpan={12}>
                    <Box bg={cardBg} boxShadow="md" borderRadius="8px">
                      <AddonsList
                        userDetails={userDetails}
                        buttonColorScheme={buttonColorScheme}
                      />
                    </Box>
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Grid
                      // h={"150px"}
                      width={"100%"}
                      templateRows={"repeat(4, 1fr)"}
                      templateColumns="repeat(2, 1fr)"
                      boxShadow="md"
                      bg={cardBg}
                      borderRadius="8px"
                      mt={0}
                      gap={1}
                      p={4}
                    >
                      <GridItem rowSpan={1} colSpan={2}>
                        <Text fontSize="12px" fontWeight="bold">
                          Your credits will be refreshed on{" "}
                          {formatDateWithAmPm(
                            userDetails?.next_credits_refresh
                          )}
                        </Text>
                      </GridItem>

                      <GridItem rowSpan={1} colSpan={2}>
                        <Text fontSize="12px" fontWeight="bold">
                          Last payment date:{" "}
                          {userDetails.stripe_last_payment_date
                            ? formatDateWithAmPm(
                              userDetails?.stripe_last_payment_date
                            )
                            : "No payments yet."}
                        </Text>
                      </GridItem>
                      {isSalesBtnisvisible && (
                        <GridItem rowSpan={1} colSpan={2}>
                          <Text fontSize="12px" fontWeight="bold">
                            Go for an annual plan to get 1-month free! Drop an
                            email to our sales team to help you.
                          </Text>
                        </GridItem>
                      )}
                      <BillingPortalButton
                        buttonColorScheme={buttonColorScheme}
                      />

                      {userDetails?.stripe_has_payment_method ? null : (
                        <Button
                          colorScheme={buttonColorScheme}
                          onClick={HanleClickOnAddPaymentMethod}
                        >
                          {isLoading ? (
                            <Spinner size="xs" />
                          ) : (
                            "Add Payment Method"
                          )}
                        </Button>
                      )}
                    </Grid>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Flex>
        )}
      </Box>

      <ConfirmationModal
        type={type}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onConfirm={type === "delete" ? deleteBilling : CloseBilling}
      />
      <CouponSuccessModal
        isOpen={isCouponSuccessModalOpen}
        onOpen={onCouponSuccessModalOpen}
        onClose={onCouponSuccessModalClose}
        couponCodeSuccessMsg={couponCodeSuccessMsg}
      />
    </>
  );
};

export default Billing;
