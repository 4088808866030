import { useContext, useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import Table from "../Tables/Table";
import { fetchController } from "../../utils/FetchController/fetchController";
import { Box, Button, Flex, Text, Tooltip } from "@chakra-ui/react";
import FormsTour from "./FormsTour";
import { useNavigate } from "react-router-dom";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
import Paginator from './../../utils/Loader/Paginator';

export default function FormListPage() {
  const baseUrl = getHostDomain();
  const [status, setStatus] = useState("idle");
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [activatedPlan, setActivatedPlan] = useState({
    advanced_intents: false,
    auto_intents: false,
    bots_count: 0,
    default_plan: false,
    description: "Insighto.ai - Free Plan",
    id: "4f832d52-14e2-4fc8-beef-184109e8b99c",
    name: "Insighto.ai - Free",
    plan_lookup_key: "insightoai_free",
    premium_support: false,
    queries_count: 100,
    remove_branding: false,
    voice_seconds: 300,
    words_count: 100000,
  });
  async function fetchAllForms() {
    setStatus("loading");
    const response = await fetchController(
      baseUrl + `/api/v1/form/list?size=10&page=${page}`
    );
    setPages(response?.data?.pages);
    setTableData(
      response.data.items.map((item) => ({
        name: item.name || "",
        formFields: item.fields,
        trigger_tools: item.trigger_tools,
        fields: item.fields.map((field) => field.label).join(", "),
        form_type: item.form_type,
        trigger_instructions: item.trigger_instructions || "",
        id: item.id,
      }))
    );
    const headsData = ["name", "fields", "form_type", "trigger_instructions"];
    const columns = headsData.map((key) => ({
      Header: key,
      accessor: key,
      Filter: ({ column }) => <input {...column.filterProps} />,
    }));
    setTableColumns(columns);
    setStatus("success");
  }
  const [formTourVisibility, setFormTourVisibility] = useState(
    localStorage.getItem("formTourVisibility") ? false : true
  );
  const onCloseTour = () => {
    localStorage.setItem("formTourVisibility", false);
    setFormTourVisibility(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchAllForms();
  }, [page]);
  const { state, dispatch } = useContext(SettingContext);
  const loadUsersDetails = async () => {
    try {
      if (state) {
        setActivatedPlan(state);
      } else {
        const specialPlanRes = await fetchController(
          baseUrl +
            `/api/v1/billing/billing_plan/${state.id}`,
          "GET"
        );
        setActivatedPlan(specialPlanRes.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    loadUsersDetails();
  }, []);
const agency = useContext(AgencyContext);
const { textColor, buttonColorScheme,  } = agency;
  return (
    <Layout>
      <Box
        border="1px"
        borderRadius="md"
        height={"90svh"}
        padding={2}
        overflowY={"auto"}
      >
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          Forms
        </Text>
        {formTourVisibility ? <FormsTour onCloseTour={onCloseTour} /> : null}
        <Flex justifyContent={"flex-end"} alignItems={"center"}>
          <Tooltip
            label={
              !activatedPlan.credits?.form
                ? "Please upgrade to Gold Plan or above"
                : ""
            }
            aria-label="A tooltip"
            placement="bottom-start"
          >
            <Button
              onClick={() => navigate(`/forms/new`)}
              colorScheme={buttonColorScheme}
              isDisabled={!activatedPlan?.credits?.form}
              // isDisabled={true}
            >
              Add a form
            </Button>
          </Tooltip>
        </Flex>
        <Box marginBlock={2}>
          {status === "loading" ? (
            <Loader />
          ) : (
            <>
                <Table
                  fetch={fetchAllForms}
                  table={"forms"}
                  columns={tableColumns}
                  data={tableData}
                />
                <Flex
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  marginBlock={2}
                >
                  <Paginator
                    colorScheme={buttonColorScheme}
                    onChangePage={setPage}
                    page={page}
                    pages={pages}
                  />
                </Flex>
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
