import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
} from "@chakra-ui/react";
import { IoEllipsisVerticalSharp, IoEyeOutline } from "react-icons/io5";
import { LiaToolsSolid } from "react-icons/lia";
import { MdCallReceived, MdDelete } from "react-icons/md";
import { RxComponentPlaceholder } from "react-icons/rx";

export default function LogRow(props) {
  return (
    <Tr>
      <Td>
        <Flex gap={2}>
          <Box as="span">
            {props.log.tool_icon ? (
              <img
                src={props.log.tool_icon}
                alt="Logo"
                height={"16px"}
                width={"16px"}
              />
            ) : (
              <LiaToolsSolid
                size={20}
                style={{
                  margin: "auto",
                }}
              />
            )}
          </Box>
          <Box as="span">{props.log.tool_name}</Box>
        </Flex>
      </Td>
      <Td>{props.log.tool_user_name}</Td>
      <Td>{props.log.tool_function_name}</Td>
      <Td>{props.log.widget_name}</Td>
      <Td>
        <Menu>
          <MenuButton
            colorScheme="blue"
            size={"sm"}
            as={IconButton}
            aria-label="Options"
            icon={<IoEllipsisVerticalSharp />}
            variant="outline"
          />
          <MenuList>
            <MenuItem
              onClick={() => props.onOpenShowModal(props.log)}
              icon={<IoEyeOutline />}
            >
              Show
            </MenuItem>
            <MenuItem
              onClick={() => {
                const json = JSON.parse(props.log.call_response);
                props.onOpenCallDetailsModal({
                  json,
                  title: "Call response",
                });
              }}
              icon={<MdCallReceived />}
            >
              Call response
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.onOpenCallDetailsModal({
                  json: props.log.call_arguments,
                  title: "Call arguments",
                });
              }}
              icon={<RxComponentPlaceholder />}
            >
              Arguments
            </MenuItem>
            <MenuItem
              onClick={() => props.onDeleteLog(props.log)}
              icon={<MdDelete />}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
