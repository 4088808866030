import { Box, Flex, Heading } from '@chakra-ui/react';
import React, { useContext } from 'react';
import Loader from '../../utils/Loader/Loader';
import { AgencyContext } from '../Settings/AgencyProvider/AgencyProvider';
import Table from '../Tables/Table';
const AgencyBillingPlanTable = ({ tableData, columns, fetch, loading, tabName }) => {
    const agency = useContext(AgencyContext);
    const { textColor } = agency

    return (
        <>
            <Box h="75vh" overflowY="scroll"  >
                <Flex justifyContent="space-between" alignItems="center" mb="4">
                    <Heading color={textColor} p={4} fontSize="xl">
                        {tabName}
                    </Heading>
                </Flex>
                {loading ? (
                    <Loader />
                ) : (
                    <Box height="auto">
                        <Table
                            fetch={fetch}
                            columns={columns}
                            data={tableData}
                            table="agencybillingPlan"
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default AgencyBillingPlanTable