import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import LogRow from "./LogRow";


export default function LogsTable(props) {
  return (
    <TableContainer  p={3} border={"1px solid lightgray"} borderRadius={"md"}>
      <Table size={"sm"} variant="striped">
        <Thead>
          <Tr>
            <Th>Tool name</Th>
            <Th>Tool user name</Th>
            <Th>Tool function name</Th>
            <Th>Widget name</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.logs.map((log) => (
            <LogRow
              key={log.id}
              onOpenShowModal={props.onOpenShowModal}
              onOpenCallDetailsModal={props.onOpenCallDetailsModal}
              onDeleteLog={props.onDeleteLog}
              log={log}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
